import styled from '@emotion/styled';

import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { Spacing } from '../../../common/Spacing';
import useInfoBoxExpander from '../../hooks/useInfoBoxExpander';
import YoutubeWrapper from './YoutubeWrapper';

import { ItemType } from '../Markers/RestaurantMarkers';
import DefaultPlaceInfoSection from './DefaultPlaceInfoSection';
import AdditionalInfoSection from './AdditionalInfoSection';
import AccessoryBar from './AccessoryBar';
import Skelton from './Skelton';
import { useStorage } from '../../../../hooks/useStorage';

function InfoBox() {
  const { watch, setValue } = useFormContext();
  const selectedItemValue: ItemType | undefined = watch('selectedItem');

  const [, setOpenInfoBoxToolTip] = useStorage('openInfoBoxToolTip');

  const openInfoBoxToolTipValue = watch('openInfoBoxToolTip');
  const sheetRef = useRef<BottomSheetRef>(null);
  const innerContentsRef = useRef<HTMLDivElement>(null);
  const { blockingBackground } = useInfoBoxExpander(sheetRef);
  const closeHandler = () => {
    setValue('infoBoxHeight', undefined);
    setValue('selectedItem', undefined);
  };

  const closeInfoBoxToolTip = () => {
    setValue('openInfoBoxToolTip', true);
    setOpenInfoBoxToolTip(true);
  };

  return (
    <BottomSheet
      className="bottomSheet-infoBox"
      open={selectedItemValue?.index !== undefined}
      ref={sheetRef}
      blocking={blockingBackground}
      onDismiss={closeHandler}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        const bottomSheetHeader = (e.target as HTMLElement)?.getAttribute('data-rsbs-header');
        if (!bottomSheetHeader) return;
        setValue('infoBoxHeight', '100%');
        closeInfoBoxToolTip();
      }}
      onTouchMove={(e: React.TouchEvent<HTMLDivElement>) => {
        const bottomSheetHeader = (e.target as HTMLElement)?.getAttribute('data-rsbs-header');
        if (!bottomSheetHeader) return;
        const yTouchPos = e?.touches[0]?.clientY;
        console.log('yTouchPos', yTouchPos);
        if (window.innerHeight - yTouchPos > (window.innerHeight / 3) * 2) {
          setValue('infoBoxHeight', '100%');
          closeInfoBoxToolTip();
        } else {
          setValue('infoBoxHeight', undefined);
        }
      }}
      onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => {
        const timer = setTimeout(() => {
          const bottomSheetHeader = (e.target as HTMLElement)?.getAttribute('data-rsbs-header');
          if (!bottomSheetHeader) return;
          const target = e.target as HTMLElement;
          const targetOffsetTop = target.getBoundingClientRect().top;
          if (window.innerHeight - targetOffsetTop > (window.innerHeight / 3) * 2) {
            setValue('infoBoxHeight', '100%');
            closeInfoBoxToolTip();
          } else {
            setValue('infoBoxHeight', undefined);
          }
        }, 400);

        return () => clearTimeout(timer);
      }}
      defaultSnap={({ minHeight }) => minHeight}
      snapPoints={({ maxHeight, minHeight }) => [
        Math.min(minHeight, (window.innerHeight / 5) * 3),
        maxHeight - maxHeight / 10,
      ]}
      onSpringEnd={(event) => {
        // 위로 확장 드래깅 이벤트 발생 검출
        if (
          (event.type === 'RESIZE' && event.source === 'element') ||
          (event.type === 'SNAP' && event.source === 'dragging')
        ) {
          if ((sheetRef.current?.height ?? 0) > (window.innerHeight / 3) * 2) {
            setValue('infoBoxHeight', '100%');
            closeInfoBoxToolTip();
          } else {
            setValue('infoBoxHeight', undefined);
          }
        }
      }}
    >
      {!openInfoBoxToolTipValue && (
        <ToolTipWrapper>
          <ToolTip>아래 버튼을 누르고 위로 올려보세요</ToolTip>
        </ToolTipWrapper>
      )}
      {selectedItemValue ? (
        <Wrapper onTouchMove={(e) => e.stopPropagation()} ref={innerContentsRef}>
          <YoutubeWrapper
            index={selectedItemValue?.index}
            youtubeUrl={selectedItemValue?.youtubeUrl}
          />
          <Spacing height={14} />
          <ContentsWrapper>
            {/* 바텀시트 기본으로 보여주는 정보 = <DefaultPlaceInfoSection/> */}
            <DefaultPlaceInfoSection />
            <Spacing height={14} />

            {/* infoBox 확장시에 나오는 추가정보들 */}
            <AdditionalInfoSection />
            {/* infoBox 확장시에 나오는 지도 이동 버튼 */}
          </ContentsWrapper>
        </Wrapper>
      ) : (
        <Skelton />
      )}
    </BottomSheet>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  height: auto;
  min-height: 100px;

  background-color: white;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-bottom: none;

  box-sizing: border-box;
  overflow: hidden;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0 15px 15px 15px;
`;

const ToolTipWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: -30px;
  left: 0;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ToolTip = styled.div`
  width: auto;
  height: 30px;
  background-color: #3d3d3d;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
  opacity: 0.8;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #3d3d3d;
    border-bottom: 0;
    margin-left: -6px;
    margin-bottom: -6px;
  }
`;

export default InfoBox;
