// 기본 포크 이미지
const DEFAULT_PIN = 'https://blog.kakaocdn.net/dn/uH2dr/btr0Gbn9PEJ/11fhgd9KleULM2b9WdzrB1/img.png';
const SELECTED_PIN =
  'https://blog.kakaocdn.net/dn/bkSbb7/btr01TMjM93/BWE3z9mQBDJZY5Zou197Ek/img.png';

// 쯔양 이미지
const ZZYANG_DEFAULT_PIN =
  'https://blog.kakaocdn.net/dn/b6Iosn/btr1UTyWR4d/6y6u9r77uw9Z6peorshgWK/img.png';
const ZZYANG_SELECTED_PIN =
  'https://blog.kakaocdn.net/dn/cC96uI/btr1Xxvw9F2/Yjl2IGnrrw6ka203eKo5p1/img.png';

// 성시경 이미지
const SSG_DEFAULT_PIN =
  'https://blog.kakaocdn.net/dn/b4dq03/btr1USUl9qD/2kwb9Ibu3skzqhLTfowokk/img.png';
const SSG_SELECTED_PIN =
  'https://blog.kakaocdn.net/dn/DaX2J/btr1XQuUhZs/wuopTcUtjWDKTJWHYzvy41/img.png';

// 빅페이스 이미지
const BIGFACE_DEFAULT_PIN =
  'https://blog.kakaocdn.net/dn/1s3If/btr2H6396mD/HN1lP84dNFnT8ymhIpm1vk/img.png';
const BIGFACE_SELECTED_PIN =
  'https://blog.kakaocdn.net/dn/GeDLy/btr2vVXLY3t/MWQjZ7Cs6tmvkJmhKA3b3K/img.png';

// 김사원세끼 이미지
const KIMSAWON_DEFAULT_PIN =
  'https://blog.kakaocdn.net/dn/cfSx4R/btr3U2GEixz/7V1T19TyGEJOJ51hK2FLIK/img.png';

const KIMSAWON_SELECTED_PIN =
  'https://blog.kakaocdn.net/dn/bjRhjU/btr3SwaHCUp/4Yo7RuBlkOucMIk7ZLvm00/img.png';

export {
  DEFAULT_PIN,
  SELECTED_PIN,
  ZZYANG_DEFAULT_PIN,
  ZZYANG_SELECTED_PIN,
  SSG_DEFAULT_PIN,
  SSG_SELECTED_PIN,
  BIGFACE_DEFAULT_PIN,
  BIGFACE_SELECTED_PIN,
  KIMSAWON_DEFAULT_PIN,
  KIMSAWON_SELECTED_PIN,
};
